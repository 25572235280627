'use client';

import { useState, useCallback, memo } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton, Avatar, Popover, List, ListItem, ListItemAvatar, ListItemText, Divider, ListItemButton, ListItemIcon } from '@mui/material';
import { useGetCurrentUserInfoRequest } from '@/src/components/Auth/hooks/useGetCurrentUserInfoRequest';
import { useLogoutRequest } from '@/src/components/Auth/hooks/useLogoutRequest';
const NavbarAuthButtonsInternal = () => {
  const {
    isSuccess,
    data
  } = useGetCurrentUserInfoRequest();
  const user = isSuccess && data?.user;
  const {
    mutate: logout
  } = useLogoutRequest();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  if (!user) {
    return null;
  }
  return <>
      <IconButton title="Account settings" onClick={e => setAnchorEl(e.currentTarget)} size="small" color="primary" aria-controls={isOpen ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={isOpen ? 'true' : undefined} data-sentry-element="IconButton" data-sentry-source-file="NavbarAuthButtons.tsx">
        <Avatar alt={user.firstName} sx={{
        bgcolor: 'primary.12p',
        color: 'text.primary',
        width: 32,
        height: 32
      }} data-sentry-element="Avatar" data-sentry-source-file="NavbarAuthButtons.tsx">
          {user.firstName?.at(0)}
        </Avatar>
      </IconButton>
      <Popover id="account-menu" anchorEl={anchorEl} open={isOpen} onClose={handleClose} onClick={handleClose} slotProps={{
      paper: {
        sx: {
          borderRadius: 0,
          mt: 2.1
        }
      }
    }} transformOrigin={{
      horizontal: 'right',
      vertical: 'top'
    }} anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom'
    }} data-sentry-element="Popover" data-sentry-source-file="NavbarAuthButtons.tsx">
        <List data-sentry-element="List" data-sentry-source-file="NavbarAuthButtons.tsx">
          <ListItem data-sentry-element="ListItem" data-sentry-source-file="NavbarAuthButtons.tsx">
            <ListItemAvatar data-sentry-element="ListItemAvatar" data-sentry-source-file="NavbarAuthButtons.tsx">
              <Avatar alt={user.firstName} sx={{
              bgcolor: 'primary.12p',
              color: 'text.primary'
            }} data-sentry-element="Avatar" data-sentry-source-file="NavbarAuthButtons.tsx">
                {user.firstName?.at(0)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${user.firstName} ${user.lastName}`} primaryTypographyProps={{
            variant: 'h5',
            mb: 0.5
          }} secondary={user.company?.name} secondaryTypographyProps={{
            variant: 'paragraph'
          }} data-sentry-element="ListItemText" data-sentry-source-file="NavbarAuthButtons.tsx" />
          </ListItem>
          <Divider sx={{
          mb: 1
        }} data-sentry-element="Divider" data-sentry-source-file="NavbarAuthButtons.tsx" />
          {/*{!!user.admin && (*/}
          {/*  <ListItem disablePadding>*/}
          {/*    <ListItemButton href="/company/settings/info">*/}
          {/*      <ListItemIcon sx={{ pl: 1 }}>*/}
          {/*        <Settings />*/}
          {/*      </ListItemIcon>*/}
          {/*      <ListItemText primary="Company Settings" />*/}
          {/*    </ListItemButton>*/}
          {/*  </ListItem>*/}
          {/*)}*/}
          {/*<ListItem disablePadding>*/}
          {/*  <ListItemButton href="/user/settings/info">*/}
          {/*    <ListItemIcon sx={{ pl: 1 }}>*/}
          {/*      <AccountCircleOutlinedIcon />*/}
          {/*    </ListItemIcon>*/}
          {/*    <ListItemText primary="User Settings" />*/}
          {/*  </ListItemButton>*/}
          {/*</ListItem>*/}
          <ListItem disablePadding data-sentry-element="ListItem" data-sentry-source-file="NavbarAuthButtons.tsx">
            <ListItemButton onClick={() => logout()} data-sentry-element="ListItemButton" data-sentry-source-file="NavbarAuthButtons.tsx">
              <ListItemIcon sx={{
              pl: 1
            }} data-sentry-element="ListItemIcon" data-sentry-source-file="NavbarAuthButtons.tsx">
                <LogoutIcon data-sentry-element="LogoutIcon" data-sentry-source-file="NavbarAuthButtons.tsx" />
              </ListItemIcon>
              <ListItemText primary="Logout" data-sentry-element="ListItemText" data-sentry-source-file="NavbarAuthButtons.tsx" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>;
};
export const NavbarAuthButtons = memo(NavbarAuthButtonsInternal);